/*
*  Author: Suki Law
*  Copyright 2023
*/

@font-face {
    font-family: 'Lexend_Deca';
    src: url('./fonts/Lexend Deca/LexendDeca-VariableFont_wght.ttf');
}

/* For small mobile phones: */
@media only screen and (max-width: 500px) {
    .project-text {
        -webkit-column-count: 1 !important;
        /* Old Chrome, Safari and Opera */
        -moz-column-count: 1 !important;
        /* Old Firefox */
        column-count: 1 !important;
    }
}

/* For mobile phones: */
@media only screen and (max-width: 768px) {
    p {
        font-size: 16px;

    }

    h2 {
        margin-top: 10px;
        font-size: 18px;
    }

    h3 {
        font-size: 16px;
    }

    .hide-on-mobile {
        display: none;
    }

    .page-logo {
        font-size: 20px;
    }

    .dashboard {
        font-size: 30px;
        line-height: 40px;
        width: 100%;
    }

    .home-description p {
        font-size: 20px !important;
    }

    .horizontal-menu ul li a {
        font-size: 20px !important;
    }

    .horizontal-menu {
        bottom: 100px !important;
    }

    .dashboard ul {
        display: none;
    }

    .mobile-menu {
        margin-top: 25%;
        padding-top: 10%;
        position: fixed;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background: #233f29;
        z-index: 999;
    }

    .dashboard .mobile-menu ul {
        display: inline-block;
        width: 98%;
        overflow: hidden;
        text-align: center;
        padding: 5px;
    }

    .float-left {
        float: none !important;
    }

    .float-right {
        float: none !important;
    }

    .short_intro {
        margin-bottom: 20px;
    }

    .project-title {
        text-align: center;
        margin-top: 10px !important;
        margin-bottom: 10px !important;
    }

    .bio {
        padding-top: 25px !important;
        width: 90% !important;
    }

    .album_frame img {
        width: 100%;
        display: block;
    }

    .logo-link {
        width: 180px !important;
        height: 60px !important;
        background-size: contain;
    }

    .half {
        width: 100% !important;
    }

    .work_title {
        height: 25px !important;
        background-size: auto 15px;
    }
}

/* For desktop: */
@media only screen and (min-width: 769px) {
    html {
        overflow-y: scroll !important;
    }

    .hide-on-desktop {
        display: none;
    }

    p {
        font-size: 18px;
    }

    .p1 {
        font-size: 18px;
    }

    .p2 {
        font-size: 16px;
        font-weight: 300;
    }

    .p3 {
        font-size: 16px;
        font-weight: 500;
    }

    .p4 {
        font-size: 16px;
        font-weight: 100;
    }


    .page-logo {
        font-size: 35px;
        line-height: 35px;
    }

    .menu-toggle {
        display: none;
    }

    .dashboard {
        font-size: 27px;
        line-height: 40px;
        min-height: 70px;
        width: 10%;
        min-width: 150px;
        float: right;
    }

    .short_intro {
        width: 45%;
        min-width: 600px;
        margin-bottom: 20px;
    }

    .bio {
        max-width: 1280px;
        margin-left: 80px;
        margin-right: 80px;
    }

    .bio_contacts {
        display: inline-block;
        width: 45%;
        min-width: 600px;
    }

    .bio_about {
        width: 52%;
        min-width: 600px;
    }

    .album_frame img {
        width: 100%;
        display: block;
    }
}

html {
    max-width: 100%;
    overflow-x: hidden;
    overflow-y: scroll;
    height: 100%;
}

body {
    margin: 0;
    font-family: Lexend_Deca;
    max-width: 100%;
    height: 100%;
    background: white;
    color: black;
}

* {
    margin: 0;
    padding: 0;
}

.full_height {
    height: 100%;
}

.removed {
    display: none;
}

.main_container {
    height: 100%;
}

.home_container {
    height: 100%;
}

.right {
    text-align: right !important;
}

.left {
    text-align: left !important;
}

.center {
    text-align: center !important;
}

.float-left {
    float: left;
}

.float-right {
    float: right;
}

.half {
    width: 48%;
    display: inline-block;
}

.divide-title {
    background: #233f29;
    display: inline-block;
    height: 50px;
    width: 100%;
    color: black;
    line-height: 50px;
    font-size: 22px;
    font-weight: bold;
    margin-top: 60px;
}

.home-description:before {
    content: "";
    display: inline-block;
    height: 50%;
    vertical-align: middle;
}

.home-description {
    height: 60%;
    margin-left: 20px;
    margin-right: 20px;
}

.home-description p {
    font-size: 30px;
    color: white;
}

.horizontal-menu a {
    font-family: Lexend_Deca;
    bottom: 25px;
    text-align: center;
    position: absolute;
    width: 100%;
    color: #d7d4c6;
}

.horizontal-menu ul {
    width: 100%;
    margin: 1px auto 0 auto;
    height: 50px;
    padding: 0;
    display: table;
    text-align: center;
}

.horizontal-menu ul li {
    list-style-type: none;
    display: table-cell;
    vertical-align: middle;
}

.horizontal-menu ul li a {
    font-size: 45px;
    color: white;
    display: block;
}

.zeroOpacity {
    opacity: 0 !important;
    transition: opacity 500ms ease-in;
}

.fullOpacity {
    opacity: 1;
    transition: opacity 500ms ease-out;
}

p a:hover {
    color: grey;
}

a {
    color: black;
    text-decoration: none;
    cursor: pointer;
}

a:visited {
    color: rgb(53, 53, 53);
    text-decoration: none;
}

p {
    color: black;
}

.bio p {
    color: #1b1b1b;
}

h1 {
    font-size: 30px;
    text-align: left;
    color: black;
    font-weight: Bold;
}

h2 {
    font-size: 20px;
    font-weight: 200;
}

h3 {
    font-size: 20px;
    font-weight: 900;
}

h4 {
    font-size: 50px;
    font-weight: 900;

}

h5 {
    font-size: 20px;
    font-weight: 500;

}

/* TODO: this needs to be sorted out in a nicer way */
.vertical-middle {
    margin-top: 10%;
}

hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid black;
    margin-left: 0px;
    margin-right: 0px;
}

.top-bar {
    display: flex;
    justify-content: space-between;
    background-color: white;
    height: 60px;
    border-bottom: 1px solid;

    /* The following lets the div stick to the top */
    position: sticky;
    top: 0;
    z-index: 999;
}

.logo-link {
    width: 20%;
}

.top-menu {
    width: 10%;
    padding: 1.5%;
    text-align: center;
    font-weight: medium;
}

.content .page-logo {
    padding: 3%;
}

.page-logo {
    letter-spacing: 0px;
    vertical-align: top;
    user-select: none;
    text-align: center;
    padding: 1%;
}

.menu {
    overflow: auto;
    z-index: 1;
    background: #233f29;
}

.menu_content {
    margin: 3%;
    position: relative;
}

.menu-toggle {
    position: absolute;
    top: 0;
    right: 3%;
    float: right;
    height: 35px;
    width: 25px;
    font-size: 30px;
    text-align: center;
    vertical-align: middle;
}

.menu-toggle div {
    text-align: center;
}


.bar1,
.bar2,
.bar3 {
    width: 25px;
    height: 3px;
    background-color: black;
    margin: 6px 0;
    transition: 0.4s;
}

.toggled .bar1 {
    -webkit-transform: rotate(-45deg) translate(-7.5px, 6px);
    transform: rotate(-45deg) translate(-7.5px, 6px);
}

.toggled .bar2 {
    opacity: 0;
}

.toggled .bar3 {
    -webkit-transform: rotate(45deg) translate(-6px, -6px);
    transform: rotate(45deg) translate(-6px, -6px);
}

.icon {
    display: none;
}

.dashboard {
    text-align: left;
    overflow-x: hidden;
    vertical-align: top;
    position: absolute;
    top: 0;
    right: 0px;
    font-family: Lexend_Deca;
}

.dashboard a {
    color: black;
}

.bright_dashboard a {
    color: white !important;
}

.dashboard li {
    list-style-type: none;
}

.active {
    text-decoration: underline;
    color: black !important;
}

.dash_line {
    height: 8px;
    background: rgb(60, 60, 60);
}

.content {
    position: relative;
    z-index: 0;
    margin: auto;
    text-align: left;
    background: #f6f6f6;
}



.shadow {
    box-shadow: 0 2px 12px rgba(14, 14, 14, 0.5);
}

.copyright {
    font-size: 11px;
    color: rgba(70, 70, 70, 0.6);
    margin: 10px auto;
    padding-right: 10px;
    text-align: right;
    width: 1024px;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.fill-width {
    width: 100%;
}

.fill-page {
    height: calc(100vh - 60px);
    padding: 0 !important;
    min-height: 625px;
}

/* Home Video */
.home_background {
    background-color: #233f29;
}

.wallpaper-background {
    background-size: cover;
    position: fixed;
    min-width: 100%;
    min-height: 100%;
    background-image: url('./images/mirador_main.jpg');
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
    z-index: -100;
}

/*Home Contacts*/
.bottom-bar {
    background-color: #233f29;
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    padding: 40px;
    color: #f6f6f6;
}

.bottom-bar p {
    color: #f6f6f6;
}

@keyframes marquee {
    0% {
        background-position: 0;
    }

    100% {
        background-position: -100000px;
    }
}

@-webkit-keyframes marquee {
    0% {
        background-position: 0;
    }

    100% {
        background-position: -100000px;
    }
}

/*Home Works*/
.selected_projects {
    /* This CSS hack gives us the offset at the element we scroll to */
    padding-top: 60px;
    margin-top: -60px;
    height: 30px;
    padding-left: 20px;
    padding-top: 70px;
    border-bottom: 1px solid;
}

.work_item {
    position: relative;
}

.project_entry {
    position: relative;
    height: 120px;
    padding: 60px;
    border-bottom: 1px solid;
}

.project_entry img {
    width: 100%;
    display: block;
}

.project_text {
    position: relative;
    z-index: 2;
    margin-left: 80px;
    font-weight: 900;
}

.bright_base p, .bright_base h4, .bright_base h5 {
    color: #233f29;
}

.dark_base p, .dark_base h4, .dark_base h5 {
    color: white;
    text-shadow: 1px 1px 3px #233f29;
}

.project_image_container {
    width: 100%;
    height: 100%;
    background-size: cover;
    z-index: 1;
    opacity: 1;
    display: block;
    position: absolute;
    top: 0px;
    left: 0px;
}

.project_entry_overlay {
    transition: .5s ease;
}

.dark_base .project_entry_overlay {
    opacity: 0;
}

.project_entry_overlay {
    background-color: #d7d4c6;
}

.bright_base .project_entry:hover .project_entry_overlay {
    opacity: 0;
}

.dark_base .project_entry:hover .project_entry_overlay {
    opacity: 1;
}

.bright_base .project_entry:hover p, .bright_base .project_entry:hover h4, .bright_base .project_entry:hover h5 {
    color: white;
    text-shadow: 1px 1px 3px #233f29;
}

.dark_base .project_entry:hover p, .dark_base .project_entry:hover h4, .dark_base .project_entry:hover h5 {
    color: #233f29;
    text-shadow: none;
}

.album_image {
    margin: 30px 15px auto auto;
    /* width: 65%; */
    width: 100%;
    max-width: 800px;

}


/* ABOUT */
.bio {
    text-align: left;
    display: inline-block;
}

.bio a {
    color: rgba(0, 0, 0, 0.78);
    text-decoration: underline;
}

.flex-box {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    padding-top: 60px;
    padding-left: 70px;
    padding-right: 70px;

}

.left-column-flex-box {
    width: 40%;
    font-weight: 300;
}

.right-column-flex-box {
    width: 40%;
}

.cross-column-flex-box {
    width: 5%;
}

/*Projects*/
.project-page-flex-box {
    display: flex;
    align-items: stretch;
}

.back-flex-box {
    width: 4%;
    min-width: 50px;
    border-right: 1px solid;
}

.back-flex-box:hover {
    background-color: #f6f6f6;
}

.back-flex-box img {
    top: 50%;
    position: sticky;
    opacity: 0.75;
}

.back-flex-box:hover img {
    opacity: 1;
}

.project-content-flex-box {
    width: 100%;
    background-color: #f6f6f6;
}

.project-cover {
    width: 100%;
    display: block;
}

.project-title {
    line-height: 13px;
    margin: 20px;
    font-weight: bold;
}

.project-text {
    font-family: "Lexend_Deca";
    margin-top: 3%;
    margin-bottom: 4%;
    -webkit-column-count: 2;
    /* Old Chrome, Safari and Opera */
    -moz-column-count: 2;
    /* Old Firefox */
    column-count: 2;
    column-gap: 70px;
}

.project-video {
    width: 100%;
    max-width: 800px;
    margin: auto;
}

/* GAME */
.logo,
.progress {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.logo {
    background: url('./images/TemplateData/progressLogo.Dark.png') no-repeat center / contain;
    width: 154px;
    height: 130px;
}

.progress {
    height: 18px;
    width: 141px;
    margin-top: 110px;
    border: 1px white solid;
}

.progress .empty {
    background: url('./images/TemplateData/progressEmpty.Dark.png') no-repeat left / cover;
    float: right;
    width: 100%;
    height: 100%;
    display: block;
}

.progress .full {
    background: url('./images/TemplateData/progressFull.Dark.png') no-repeat right / cover;
    float: left;
    width: 0%;
    height: 100%;
    display: block;
}

.logo.Dark {
    background-image: url('./images/TemplateData/progressLogo.Dark.png');
}

canvas {
    display: block;
}


/* Home Effect */

#webGLApp {
    position: relative;
    /* top: 0;
    left: 0; */
}

.home_effect_img {
    position: absolute;
    /* opacity: 0; */
    /* display: none; */
}

/* Sliding Effect */
.pageSlider-exit {
    z-index: 1;
}

.pageSlider-enter {
    z-index: 10;
}

.pageSlider-enter.leftToRight {
    transform: translate3d(100%, 0, 0);
}

.pageSlider-enter.rightToLeft {
    transform: translate3d(-100%, 0, 0);
}

.pageSlider-enter.topToBottom {
    transform: translate3d(0, 100%, 0);
}

.pageSlider-enter.bottomToTop {
    transform: translate3d(0, -100%, 0);
}

.pageSlider-enter.pageSlider-enter-active {
    transform: translate3d(0, 0, 0);
    transition: all 600ms;
}